import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, isNil } from 'lodash';

import { updateBooking } from 'store/v1/bookings/bookings.actions.js';
import { updateExpense } from 'store/v1/expenses/expenses.actions.js';
import {
  updateResourceSlot,
  updateBudgetExpense
} from 'store/v1/productions/productions.actions.js';

import { formatRateSummary } from 'v1/helpers/budgetHelper';
import {
  Flyover,
  Grid,
  GridCell,
  Label,
  NumberInput,
  RateInput
} from 'v1/components/shared';
import { EntityType } from '__types__';

const RateEditFlyover = ({ rate, defaultCurrency, type }) => {
  const dispatch = useDispatch();
  const formattedRate = {
    ...rate,
    amount: rate.amount,
    currency: rate.currency || defaultCurrency
  };

  const handleRateChange = useCallback(
    val => {
      const value = {
        ...formattedRate,
        ...val,
        interval: val.interval || 'DAILY',
        quantity: !isNil(val.quantity) ? val.quantity : ''
      };
      if (value.slotCategoryId) {
        dispatch(
          updateExpense(value.id, {
            commit_rate_amount: value.amount,
            commit_rate_interval: value.interval,
            commit_rate_quantity: Number(value.quantity)
              ? value.quantity
              : null,
            commit_rate_currency: value.currency
          })
        );
      } else if (value.isBookingRate) {
        dispatch(
          updateBooking(value.id, {
            rate_amount: value.amount,
            rate_interval: value.interval,
            rate_currency: value.currency
          })
        );
      } else {
        const data = {
          estimate_rate_amount: value.amount,
          estimate_rate_interval: value.interval,
          estimate_rate_currency: value.currency,
          estimate_rate_quantity: Number(value.quantity) ? value.quantity : null
        };
        type === 'EXPENSE'
          ? dispatch(
              updateBudgetExpense(
                {
                  production_id: value.productionId,
                  budget_expense_id: value.id
                },
                data
              )
            )
          : dispatch(
              updateResourceSlot(
                {
                  production_id: value.productionId,
                  resource_slot_id: value.id
                },
                data
              )
            );
      }
    },
    [dispatch, type, formattedRate]
  );

  const handleCloseRateFlyover = () => {
    if (
      formattedRate &&
      (!formattedRate.quantity || isNaN(formattedRate.quantity))
    ) {
      onRateChange({ ...formattedRate, quantity: 1 });
    }
  };

  const onRateChange = useCallback(debounce(handleRateChange, 300), []);

  return (
    <Flyover onClose={() => handleCloseRateFlyover()}>
      <Flyover.Trigger>
        {rate && rate.amount ? (
          <div className="inset-S text-13-600">
            {formatRateSummary(rate, defaultCurrency)}
          </div>
        ) : (
          <div className="inset-S text-13-600-eggplant-lightest">Add rate</div>
        )}
      </Flyover.Trigger>
      <Flyover.Content clearance="flush" width="trigger">
        <div className="inset-S">
          <div className={!rate.isBookingRate ? 'stack-S' : ''}>
            <RateInput
              size="S"
              appearance="underline"
              type={
                type === 'EXPENSE' ? EntityType.EXPENSE : EntityType.BOOKING
              }
              value={formattedRate}
              onBlur={v => onRateChange({ ...formattedRate, ...v })}
            />
          </div>
          {!rate.isBookingRate && (
            <Grid gutters="S">
              <GridCell width="auto">
                <Label>Quantity</Label>
              </GridCell>
              <GridCell width="1/4">
                <NumberInput
                  appearance="underline"
                  size="S"
                  value={formattedRate.quantity}
                  placeholder="Qty"
                  onChange={v =>
                    onRateChange({ ...formattedRate, quantity: v })
                  }
                />
              </GridCell>
            </Grid>
          )}
        </div>
      </Flyover.Content>
    </Flyover>
  );
};

RateEditFlyover.propTypes = {
  rate: PropTypes.object,
  defaultCurrency: PropTypes.string,
  type: PropTypes.string
};

export default RateEditFlyover;
